import { analyticsEvents } from '@mindfulchefuk/analytics'
import { instrument } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'
import {
  CookieConsentChanges,
  CookieActions,
} from '@mindfulchefuk/analytics/src/types'

export const instrumentCookieBannerClicked = (cookieAction: CookieActions) => {
  const { name, data } = analyticsEvents.cookieBannerClicked(cookieAction)

  instrument(name, { ...data })
}

export const instrumentCookieBannerShown = () => {
  const { name, data } = analyticsEvents.cookieBannerShown()

  instrument(name, { ...data })
}

export const instrumentCookieConsentUpdated = (
  consentChanges: CookieConsentChanges
) => {
  const { name, data } = analyticsEvents.cookieConsentUpdated(consentChanges)

  instrument(name, { ...data })
}

import React, { ReactElement } from 'react'
import { Image } from '@mindfulchefuk/design-system/Image'
import { Box, BoxProps } from '../layout/Box'
import { SpaceToken } from '../system/theme'

export type IllustrationTypes = keyof typeof illustrations

export const illustrations = {
  'cross-section-asparagus': {
    url: 'illustrations-cross-section-asparagus.png',
    width: 315,
    height: 375,
  },
  'chickpea-cluster': {
    url: 'illustrations-chickpea-cluster.png',
    width: 178,
    height: 156.4,
  },
  'chopped-beetroot-one': {
    url: 'illustrations-chopped-beetroot-01.png',
    width: 140,
    height: 139,
  },
  'cross-section-beetroot-one': {
    url: 'illustrations-cross-section-beetroot.png',
    width: 144,
    height: 147,
  },
  'chopped-squash-one': {
    url: 'illustrations-chopped-squash-01.png',
    width: 45,
    height: 54,
  },
  'chopped-aubergine-three': {
    url: 'illustrations-chopped-aubergine-03.png',
    width: 97,
    height: 127,
  },
  'cross-section-almond-two': {
    url: 'illustrations-cross-section-almond-02.png',
    width: 56,
    height: 61,
  },
  'chopped-squash-two': {
    url: 'illustrations-chopped-squash-02.png',
    width: 60,
    height: 63,
  },
  'cross-section-squash-one': {
    url: 'illustrations-cross-section-squash-01.png',
    width: 169,
    height: 178,
  },
  'chopped-lemon-three': {
    url: 'illustrations-chopped-lemon-05.png',
    width: 95,
    height: 116,
  },
  'chopped-juice-drop-1': {
    url: 'illustrations-chopped-juice-drop-1.png',
    width: 17,
    height: 19,
  },
  'chopped-juice-drop-2': {
    url: 'illustrations-chopped-juice-drop-2.png',
    width: 17,
    height: 22,
  },
  'chopped-juice-drop-3': {
    url: 'illustrations-chopped-juice-drop-3.png',
    width: 17,
    height: 21,
  },
  'chopped-red-pepper-3': {
    width: 105,
    height: 81,
    url: 'illustrations-chopped-red-pepper-3.png',
  },
  'chopped-red-pepper-4': {
    width: 70,
    height: 78.35,
    url: 'illustrations-chopped-red-pepper-4.png',
  },
  'cross-section-lemon': {
    url: 'illustrations-cross-section-lemon-04.png',
    width: 126,
    height: 132,
  },
  'cross-section-pomegranate': {
    url: 'illustrations-cross-section-pomegranate-04.png',
    width: 220,
    height: 226,
  },
  'cross-section-courgette': {
    url: 'cross-section-courgette.png',
    width: 400,
    height: 405,
  },
  'cross-section-artichoke': {
    url: 'illustrations-cross-section-artichoke-04.png',
    width: 154,
    height: 222,
  },
  'chopped-carrot-three': {
    url: 'illustrations-chopped-carrot-05.png',
    width: 64,
    height: 63,
  },
  'chopped-beetroot-three': {
    url: 'illustrations-chopped-beetroot-03.png',
    width: 96,
    height: 123,
  },
  'chopped-tomato-two': {
    url: 'illustrations-chopped-tomato-03.png',
    width: 120,
    height: 142,
  },
  'cherry-tomato-slice': {
    url: 'illustrations-cherry-tomato-slice.png',
    width: 270,
    height: 267,
  },
  'orange-carrot-diced': {
    url: 'illustrations-orange-carrot-diced.png',
    width: 419,
    height: 389,
  },
  'cross-section-spinach': {
    url: 'illustrations-spinach.png',
    width: 234,
    height: 248,
  },
  'cross-section-chilli-diced': {
    url: 'illustrations-chilli.png',
    width: 136,
    height: 137,
  },
  'cross-section-cucumber': {
    url: 'illustrations-cross-section-cucumber-01.png',
    width: 141,
    height: 133,
  },
  'cross-section-turnip': {
    url: 'illustrations-cross-section-turnip-01.png',
    width: 170,
    height: 240,
  },
  'chopped-broccoli-03': {
    url: 'illustrations-chopped-broccoli-03.png',
    width: 92,
    height: 118,
  },
  'diced-broccoli-02': {
    url: 'chopped-broccoli-diced-2.png',
    width: 378,
    height: 406,
  },
  'chopped-grapefruit-03': {
    url: 'illustrations-chopped-grapefruit-03.png',
    width: 87,
    height: 77,
  },
  'cross-section-squash-02': {
    url: 'illustrations-cross-section-squash-02.png',
    width: 136,
    height: 242,
  },
  'chopped-carrot-twirl': {
    url: 'illustrations-chopped-carrot-twirl.png',
    width: 70,
    height: 64,
  },
  'chopped-carrot-twirl-x2': {
    url: 'chopped-carrot-twirl-x2.png',
    width: 140,
    height: 128,
  },
  'cross-section-pumpkin-02': {
    url: 'illustrations-cross-section-pumpkin-02.png',
    width: 94,
    height: 61,
  },
  'cross-section-pumpkin-01': {
    url: 'illustrations-cross-section-pumpkin-01.png',
    width: 128,
    height: 71,
  },
  'cross-section-apricot': {
    url: 'illustrations-cross-section-apricot.png',
    width: 132,
    height: 130,
  },
  'cross-section-kale': {
    url: 'illustrations-cross-section-kale.png',
    width: 138,
    height: 124,
  },
  'cross-section-red-onion': {
    url: 'illustrations-cross-section-red-onion.png',
    width: 109.59,
    height: 106.51,
  },
  'cross-section-garlic': {
    url: 'illustrations-cross-section-garlic.png',
    width: 104,
    height: 106,
  },
  'cross-section-sweet-corn-1': {
    url: 'illustrations-cross-section-sweet-corn-1.png',
    width: 55.51,
    height: 59.68,
  },
  'cross-section-sweet-corn-2': {
    url: 'illustrations-cross-section-sweet-corn-2.png',
    width: 43.1,
    height: 58.6,
  },
  'cross-section-sweet-corn-3': {
    url: 'illustrations-cross-section-sweet-corn-3.png',
    width: 57.95,
    height: 48.25,
  },
  'cross-section-aubergine-sliced': {
    url: 'illustrations-cross-section-aubergine-sliced.png',
    width: 87,
    height: 212,
  },
  'cross-section-strawberry': {
    url: 'illustrations-cross-section-strawberry.png',
    width: 86,
    height: 93,
  },
  'cross-section-chestnut': {
    url: 'illustrations-cross-section-chestnut.png',
    width: 86,
    height: 86,
  },
  'cross-section-aubergine-sliced-1': {
    url: 'illustrations-cross-section-aubergine-sliced-1.png',
    width: 152.17,
    height: 151.6,
  },
  'cross-section-raspberry': {
    url: 'illustrations-cross-section-raspberry.png',
    width: 40,
    height: 38,
  },
  'cross-section-penne-2': {
    url: 'illustrations-cross-section-penne-2.png',
    width: 92,
    height: 46,
  },
  'cross-section-penne-1': {
    url: 'illustrations-cross-section-penne-1.png',
    width: 93.76,
    height: 49.63,
  },
  'cross-section-carrot': {
    url: 'illustrations-cross-section-carrot.png',
    width: 90,
    height: 93,
  },
  'courgette-diced-3': {
    url: 'illustrations-courgette-diced-3.png',
    width: 85,
    height: 51,
  },
  'rhubarb-diced-2': {
    url: 'illustrations-rhubarb-diced-2.png',
    width: 62,
    height: 68,
  },
  'chopped-red-onion-diced-2': {
    url: 'illustrations-chopped-red-onion-diced-2.png',
    width: 196.12,
    height: 205.56,
  },
  'chopped-cucumber-diced-2': {
    url: 'illustrations-chopped-cucumber-diced-2.png',
    width: 92,
    height: 145,
  },
  'cross-section-edamame-bean-1': {
    url: 'illustrations-cross-section-edamame-bean-1.png',
    width: 40,
    height: 28,
  },
  'cross-section-edamame-bean-2': {
    url: 'illustrations-cross-section-edamame-bean-2.png',
    width: 26,
    height: 38,
  },
  'cross-section-edamame-bean-3': {
    url: 'illustrations-cross-section-edamame-bean-3.png',
    width: 36,
    height: 28,
  },
  'cross-section-radish': {
    url: 'illustrations-cross-section-radish.png',
    width: 68,
    height: 72,
  },
  'chopped-carrot-diced-2': {
    url: 'illustrations-chopped-carrot-2.2.png',
    width: 136,
    height: 122,
  },
  'cross-section-runner-bean-2': {
    url: 'illustrations-cross-section-runner-bean-2.png',
    width: 98,
    height: 226,
  },
  'cross-section-mushroom-1': {
    url: 'illustrations-cross-section-mushroom-1.png',
    width: 52,
    height: 46,
  },
  'cross-section-seeds-1': {
    url: 'illustrations-cross-section-seeds-1.png',
    width: 98,
    height: 98,
  },
  'cross-section-radish-2': {
    url: 'illustrations-cross-section-radish-2.png',
    width: 136,
    height: 144,
  },
  'cross-section-leek': {
    url: 'illustrations-cross-section-leek.png',
    width: 122,
    height: 115,
  },
  'chopped-red-onion-diced-4': {
    url: 'illustrations-chopped-red-onion-diced-4.png',
    width: 254,
    height: 266,
  },
  'cross-section-coriander-1': {
    url: 'illustrations-cross-section-coriander-1.png',
    width: 95,
    height: 131,
  },
  'cross-section-goji-berries': {
    url: 'illustrations-cross-section-goji-berry-1.png',
    width: 38,
    height: 41,
  },
  'cross-section-corn-on-cob-1': {
    url: 'illustrations-cross-section-corn-on-cob-1.png',
    width: 69,
    height: 106,
  },
  'cross-section-papaya': {
    url: 'illustrations-cross-section-papaya-1.png',
    width: 222,
    height: 263,
  },
  'cross-section-orange': {
    url: 'illustrations-cross-section-orange.png',
    width: 213,
    height: 164,
  },
  'cross-section-cranberries': {
    url: 'illustrations-cross-section-cranberries.png',
    width: 91,
    height: 107,
  },
  'cross-section-watermelon': {
    url: 'illustrations-cross-section-watermelon.png',
    width: 85,
    height: 75,
  },
  'cross-section-blackberry': {
    url: 'illustrations-cross-section-blackberry.png',
    width: 63,
    height: 61,
  },
  'cross-section-cherries': {
    url: 'illustrations-cross-section-cherries.png',
    width: 52,
    height: 60,
  },
  'cross-section-brussels-sprout': {
    url: 'illustrations-cross-section-brussels-sprout.png',
    width: 59,
    height: 73,
  },
  'cross-section-cauliflower': {
    url: 'illustrations-cross-section-cauliflower.png',
    width: 99,
    height: 100,
  },
  'hp-broccoli-1': {
    url: 'homepage/broccoli-1.svg',
    width: 163,
    height: 189,
  },
  'hp-broccoli-2': {
    url: 'homepage/broccoli-2.svg',
    width: 226,
    height: 262,
  },
  'hp-carrot-chopped-1': {
    url: 'homepage/carrot-chopped-1.svg',
    width: 133,
    height: 154,
  },
  'hp-carrot-chopped-2': {
    url: 'homepage/carrot-chopped-2.svg',
    width: 93,
    height: 108,
  },
  'hp-radish-slice-1': {
    url: 'homepage/radish-slice-1.svg',
    width: 113,
    height: 131,
  },
  'inline-orange-carrot-diced': {
    width: 166,
    height: 166,
    url: 'inline/orange-carrot-diced.png',
  },
  'inline-orange-carrot-diced-2': {
    width: 131,
    height: 153,
    url: 'inline/orange-carrot-diced-2.png',
  },
  'inline-tomato-slice': {
    width: 168,
    height: 165,
    url: 'inline/tomato-slice.png',
  },
  'inline-tomato-slice-2': {
    width: 154,
    height: 132,
    url: 'inline/tomato-slice-2.png',
  },
  'inline-spinach-chilli-slice': {
    width: 241,
    height: 262,
    url: 'inline/chilli-spinach-slice.png',
  },
  'inline-chickpea-cluster': {
    width: 140,
    height: 210,
    url: 'inline/chickpea-cluster.png',
  },
  'inline-kale-tomato': {
    width: 261,
    height: 240,
    url: 'inline/kale-tomato.png',
  },
  'inline-broccoli': {
    width: 188,
    height: 202,
    url: 'inline/broccoli.png',
  },
  'inline-carrot-kale-slice': {
    width: 194,
    height: 243,
    url: 'inline/carrot-kale-slice.png',
  },
  'inline-carrot-kale-slice-2': {
    width: 200,
    height: 253,
    url: 'inline/carrot-kale-slice-2.png',
  },
  'inline-onion-slice': {
    width: 209,
    height: 223,
    url: 'inline/onion-slice.png',
  },
  'inline-spinach-leaf': {
    width: 244,
    height: 207,
    url: 'inline/spinach-leaf.png',
  },
} as const

export interface Props extends Omit<BoxProps, 'width' | 'height'> {
  type: IllustrationTypes
  className?: string
}
export default function Illustration(props: Props): ReactElement {
  const { type, ...boxProps } = props

  const { url, width, height } = illustrations[type]

  return (
    <Box
      {...boxProps}
      position={boxProps.position ?? 'absolute'}
      pointerEvents="none"
      width={width as SpaceToken}
      height={height as SpaceToken}
    >
      <Image
        resolve
        src={`illustrations/${url}`}
        width={width}
        height={height}
      />
    </Box>
  )
}

import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Properties } from 'csstype'
import { theme } from '@mindfulchefuk/design-system/system/theme'
import {
  CSSPropMapper,
  Responsive,
} from '@mindfulchefuk/design-system/system/types'
import { createCSSMapper } from '@mindfulchefuk/design-system/system/utils/createCSSMapper'
import { margin, MarginProps } from '@mindfulchefuk/design-system/system'

export interface ButtonProps extends MarginProps {
  /** The HTML element the button will render as */
  as?: string
  /** The link url */
  href?: string
  target?: '_blank' | '_self' | '_parent' | '_top'
  variant: Responsive<ButtonVariant>
  size: Responsive<ButtonSize>
  type?: 'button' | 'submit' | 'reset'
  fullWidth?: Responsive<boolean>
  textWrap?: Responsive<boolean>
  'data-testid'?: string
}

const sizes = {
  small: {
    padding: `${theme.space[2]} ${theme.space[12]}`,
    ...theme.typography['body-md-500-caps'],
  },
  medium: {
    padding: `${theme.space[10]} ${theme.space[16]}`,
    ...theme.typography['body-lg-500-caps'],
  },
  large: {
    padding: `${theme.space[14]} ${theme.space[28]}`,
    ...theme.typography['body-xl-500-caps'],
  },
}

export type ButtonSize = keyof typeof sizes
export type ButtonVariant = keyof typeof variants

const variants = {
  primary: {
    background: theme.colors.broccoli,
    borderColor: theme.colors.broccoli,
    color: theme.colors.white,
    '&:hover': {
      background: theme.colors['broccoli-400'],
      borderColor: theme.colors['broccoli-400'],
      color: theme.colors.white,
    },
    '&:disabled': {
      background: theme.colors['broccoli-200'],
      borderColor: theme.colors['broccoli-200'],
      color: theme.colors.white,
    },
  },
  'primary-white': {
    background: theme.colors.white,
    borderColor: theme.colors.white,
    color: theme.colors.broccoli,
    '&:hover': {
      background: theme.colors.salt,
      borderColor: theme.colors.salt,
      color: theme.colors.broccoli,
    },
    '&:disabled': {
      background: theme.colors['pepper-50'],
      borderColor: theme.colors['pepper-50'],
      color: theme.colors['pepper-200'],
    },
  },
  secondary: {
    background: 'transparent',
    borderColor: theme.colors.aubergine,
    color: theme.colors.aubergine,
    '&:hover': {
      background: theme.colors['aubergine-50'],
      borderColor: theme.colors.aubergine,
      color: theme.colors.aubergine,
    },
    '&:disabled': {
      background: theme.colors['aubergine-50'],
      borderColor: theme.colors['aubergine-50'],
      color: theme.colors['aubergine-100'],
    },
  },
  'secondary-white': {
    background: 'transparent',
    borderColor: theme.colors.white,
    color: theme.colors.white,
    '&:hover': {
      background: theme.colors.white,
      borderColor: theme.colors.white,
      color: theme.colors.aubergine,
    },
    '&:disabled': {
      background: theme.colors['pepper-50'],
      borderColor: theme.colors['pepper-50'],
      color: theme.colors['pepper-200'],
    },
  },
  'bbc-good-food': {
    background: theme.colors['bbc-good-food-pink'],
    borderColor: theme.colors['bbc-good-food-pink'],
    color: theme.colors.white,
    '&:hover': {
      background: theme.colors['bbc-good-food-pink-hover'],
      borderColor: theme.colors['bbc-good-food-pink-hover'],
      color: theme.colors.white,
    },
    '&:disabled': {
      background: theme.colors['bbc-good-food-pink-disabled'],
      borderColor: theme.colors['bbc-good-food-pink-disabled'],
      color: theme.colors.white,
    },
  },
}

const buttonVariants: CSSPropMapper<ButtonProps> = {
  variant: {
    transform: ({ value }) =>
      variants[value as ButtonVariant] ?? variants.primary,
  },
  size: {
    transform: ({ value }) => sizes[value as ButtonSize] ?? sizes.medium,
  },
  fullWidth: { transform: ({ value }) => ({ width: value ? '100%' : 'auto' }) },
  textWrap: {
    transform: ({ value }) => ({
      whiteSpace: value ? 'normal' : 'nowrap',
      textOverflow: value ? 'visible' : 'ellipsis',
    }),
  },
  ...margin,
}

const [buttonVariantMapper, shouldForwardProp] = createCSSMapper(buttonVariants)

export const ResetButton = styled.button<{
  fullWidth?: boolean
  display?: Properties['display']
  height?: Properties['height']
}>`
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  color: inherit;
  cursor: pointer;
  height: ${(props) => (props.height ? props.height : 'initial')};
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  ${(props) =>
    props.display &&
    css`
      display: ${props.display};
    `}

  &:disabled {
    cursor: not-allowed;
  }
`
/** @deprecated - to be replaced with RadioBox from Figma designs and will be nothing to do with Button */
const buttonLabelStyles = css`
  border-width: 1px;
  text-transform: none;
  &[data-checked],
  &[data-checked]:hover {
    background-color: ${theme.colors.aubergine};
    color: ${theme.colors.white};
  }

  &:focus-visible,
  &:focus-within {
    z-index: 2;
  }
`

export const Button = styled('button', { shouldForwardProp })<ButtonProps>`
  display: inline-block;
  box-sizing: border-box;
  border-width: 2px;
  border-style: solid;
  border-radius: 30px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.2s linear;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  ${(props) => props.as === 'label' && buttonLabelStyles};
  ${buttonVariantMapper};
`

Button.defaultProps = {
  textWrap: false,
}

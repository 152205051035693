import * as CookieConsent from 'vanilla-cookieconsent'

import { CookieCategory } from '@mindfulchefuk/features/Cookies/types'
import { instrumentCookieConsentUpdated } from '@mindfulchefuk/utils/analytics/amplitude/instrument/instrumentCookieConsent'

export function trackConsentUpdate(changes: CookieCategory[]) {
  const changedConsentEntries = changes.map((consent: CookieCategory) => {
    const consentValue = CookieConsent.acceptedCategory(consent)
      ? 'granted'
      : 'denied'
    return [consent, consentValue]
  })
  const changedConsents = Object.fromEntries(changedConsentEntries)

  instrumentCookieConsentUpdated(changedConsents)
}

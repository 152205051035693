/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import uuid from 'uuid'

import { ShopOnboardingState } from '@mindfulchefuk/features/ShopOnboarding/interfaces'
import { LineItem } from '@mindfulchefuk/query'

const NAMESPACE = 'shopOnboarding'

const initialState = {
  lineItems: [],
  lineItemsReady: false,
} as ShopOnboardingState

const shopOnboardingSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setExistingShopProduct: (state, action: PayloadAction<LineItem[]>) => {
      if (action.payload) {
        state.lineItems = action.payload
      }
      state.lineItemsReady = true
    },
    updateOnboardingShopProduct: (
      state,
      action: PayloadAction<{ productId: string; quantity: number }>
    ) => {
      const { productId, quantity } = action.payload

      const existingLineItem = state.lineItems.some(
        (lineItem) => lineItem.productId === productId
      )

      // If it's an existing line item, update the quantity
      state.lineItems = state.lineItems.map((lineItem) => {
        if (lineItem.productId === productId) {
          return { ...lineItem, quantity }
        }

        return lineItem
      })

      // If it's a new line item, add it
      if (!existingLineItem) {
        state.lineItems = [
          ...state.lineItems,
          {
            lineItemId: uuid.v4(),
            productId,
            quantity,
          },
        ]
      }

      // If the line item quantity is 0, remove it
      state.lineItems = state.lineItems.filter(
        (lineItem) => lineItem.quantity > 0
      )
    },
    resetOnboardingShopProducts: (state) => {
      state.lineItems = []
    },
  },
})

export const {
  setExistingShopProduct,
  updateOnboardingShopProduct,
  resetOnboardingShopProducts,
} = shopOnboardingSlice.actions

export default shopOnboardingSlice.reducer
